import CustomToast from '../components/Toast'
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import apiService from '../../services/apiService';
import ReCAPTCHA from 'react-google-recaptcha';
import OtpInput from 'react18-input-otp';
import 'react-toastify/dist/ReactToastify.css';

const LoginModal = ({ isOpen, onRequestClose, onVerifyOTP, onResendOTP, isShowOTP, local, stage, development, production}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otpTimer, setOtpTimer] = useState(120);
  const [uid, setUID] = useState('');

  const recaptchaRef = useRef(null);

  const handleOTPChange = (e) => {
    const otpValue = e.target.value;
    setOTP(otpValue);
    setError('');
  };

  // onRequestClose = () => {
  //   setShowOTP(false)
  //   isOpen = false
  // }

  const handleEmailChange = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
    //  validateEmail(newValue); 
};

const validateEmail = (input) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^[0-9]{10}$/;

  if (emailPattern.test(input)) {
      setEmailError('');
      setPhoneError('');
      return true;
  } else if (phonePattern.test(input)) {
      setPhoneError('');
      setEmailError('');
      return true;
  } else {
      setEmailError('');
      setPhoneError('');
      
      if (!emailPattern.test(input) && !phonePattern.test(input)) {
          if (isNaN(input)) {
              setEmailError('Please enter a valid email address.');
          } else {
              setPhoneError('Please enter a valid 10-digit phone number.');
          }
      }
  }
};


  const handleVerifyOTP = async () => {
    if (otp.length === 6 && /^\d+$/.test(otp)) {
      try {
        const obj = {
          uid,
          code:otp
        }
        const result = await apiService.post('verify-otp', obj);
        if (result && result.data && result.data.roles) {
          const roles = result.data.roles;
          const token = result.data;
          const encodedToken = encodeURIComponent(JSON.stringify(token));
          // Check the user's role and redirect accordingly
          if(process.env.REACT_APP_ENV == "development") {
            if (roles.includes('Admin') || roles.includes('JetAdmin')) {
            // window.location.href = ` http://localhost:3004/?ref=${encodedToken}`;
           window.location.href = `https://fstage-admin.inclusional.com/?ref=${encodedToken}`;
            } else if (roles.includes('OrgAdmin')) {
            // window.location.href = ` http://localhost:3000/?ref=${encodedToken}`;
            window.location.href = `https://fstage-org.inclusional.com/?ref=${encodedToken}`;
            }
            else if (roles.includes('OrgLearner')) {
             // window.location.href = `http://localhost:3001?ref=${encodedToken}`;
              window.location.href = `https://fstage-lms.inclusional.com/?ref=${encodedToken}`;
            } else {
              // Handle other roles or scenarios
              setError('Invalid user role.');
              CustomToast.error('Invalid user role.') 
            }
          } else {
            if (roles.includes('Admin') || roles.includes('JetAdmin')) {
             window.location.href = `https://admin.inclusional.com/?ref=${encodedToken}`;
            } else if (roles.includes('OrgAdmin')) {
               window.location.href = `https://orgadmin.inclusional.com/?ref=${encodedToken}`;
            }
            else if (roles.includes('OrgLearner')) {
              window.location.href = `https://learn.inclusional.com/?ref=${encodedToken}`;
            } else {
              // Handle other roles or scenarios
              setError('Invalid user role.');
              CustomToast.error('Invalid user role.') 
            }
          }
          onRequestClose()
          window.history.replaceState(null, '', window.location.href);
        } else {
          setError('Failed to verify OTP. Please try again.');
          CustomToast.error('Failed to verify OTP. Please try again.') 
        }
      } catch (error) {
        console.error('Error verifying OTP:', error.message);
        setError('Failed to verify OTP. Please try again.');
        CustomToast.error('Failed to verify OTP. Please try again.') 
      }
    } else {
      setError('Please enter a valid 6-digit OTP.');
      CustomToast.error('Please enter a valid 6-digit OTP.') 
    }
  };

  const handleResendOTP = async () => {
    setOTP(''); 
    try {
      const result = await apiService.login('login-otp', { email, did: 'android 123', dname: 'redmeA1' });
      if (result.message == "OTP Generated!") {
        CustomToast.success('Resent OTP sent successfully')
        setOtpTimer(120); // Reset the timer
        onResendOTP(); // Call parent component callback for successful OTP resend
      } else {
        setError('Failed to resend OTP. Please try again.');
        CustomToast.error(error) 
      }
    } catch (error) {
      console.error('Error resending OTP:', error.message);
      setError('Failed to resend OTP. Please try again.');
      CustomToast.error(error) 
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();

    if(!validateEmail(email)) {
      return
    }

  //  const recaptchaValue = await recaptchaRef.current.getValue();
  //   if (!recaptchaValue) {
  //     CustomToast.error('Please complete the reCAPTCHA verification.')
  //   } 
   // else {
      try {
        // Verify reCAPTCHA
        // const recaptchaResult = await apiService.post('verify-recaptcha', { recaptchaResponse: recaptchaValue });
        // if (recaptchaResult.status != "success") {
        //   CustomToast.error('Failed to verify reCAPTCHA. Please try again.')
        //   return;
        // }
  
        // Send OTP
        if(!email.includes('@')) {
          const otpResult = await apiService.login('login-otp', { mobile: email, did: 'android 123', dname: 'redmeA1' });
        if (otpResult.message == "OTP Generated!") {
          setUID(otpResult.uid)
          setShowOTP(true);
        } else {
         // alert('Failed to send OTP. Please try again.');
          CustomToast.error('Failed to send OTP. Please try again') 
        }
        } else if(email) {
          const otpResult = await apiService.login('login-otp', { email, did: 'android 123', dname: 'redmeA1' });
        if (otpResult.message == "OTP Generated!") {
          setUID(otpResult.uid)
          setShowOTP(true);
        } else {
         // alert('Failed to send OTP. Please try again.');
          CustomToast.error('Failed to send OTP. Please try again') 
        }
        }
      } catch (error) {
        console.error('Error during login:', error.message)  
        CustomToast.error(error.message) 
      }
   // }
  };

  useEffect(() => {
    let timerId;
    if (showOTP && otpTimer > 0) {
      timerId = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (otpTimer === 0) {
      clearInterval(timerId);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [showOTP, otpTimer]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      setEmailError('');
    }
    if (!isOpen) {
      setOtpTimer(120);
    }
  }, [isOpen]);

  const backToEmail = async (e) => {
    console.log("hi");
    setShowOTP(false);
  }

  const handleClose = async () => {
    onRequestClose(); 
    setShowOTP(false); 
    setOtpTimer(0); 
    setPhoneError(''); 
    setEmailError('');
  }

  return (
    <Modal
      isOpen={isOpen}
      className="LoginModal"
      onRequestClose={onRequestClose}
      isShowOTP={isShowOTP}
      contentLabel="Login Modal"
    >
      <div className="login-overlay text-center bg-white w-100 m-auto p-4">
        <button type="button" className="close-button" onClick={handleClose}>×</button>
        {showOTP && (<button onClick={backToEmail} type="button" className="login-back">&#8592; </button>)}
        <div className="w-25 m-auto">
          <img src="/images/logo.png" alt="logo" className="w-100" />
        </div>
        <hr/>
        <p className="fw-bold font-20 mb-3">Sign In</p>
          {showOTP ? (
            <>
              <p className="text-start mt-4">OTP sent to {email}</p>
              <div className="otp-input">
                <OtpInput
                  value={otp}
                  onChange={(otp) => handleOTPChange({ target: { value: otp } })}
                  numInputs={6}
                  inputStyle={{
                    border: "1px solid #CCC",
                    borderRadius: "7px",
                    width: '55px',
                    height: '55px',
                    fontSize: '20px',
                    color: '#000',
                    fontWeight: '400',
                    caretColor: 'black',
                    margin: '10px',
                  }}
                />
              </div>

              <p className='resend-otp'>
                <span className='login-resend'> 
                  <button
                    type="button"
                    className="btn btn-link primary_color text-dark-grey text-decoration-none px-2"
                    onClick={handleResendOTP}
                    disabled={otpTimer > 0}
                  >
                    You can resend OTP in
                  </button>
                </span> 
                <span className='login-time otp-timer'>{formatTime(otpTimer)}</span>
              </p>

              <button
                type="button"
                className="btn btn-md btn-primary w-100 mt-2 mb-2"
                onClick={handleVerifyOTP}
              >
                Verify OTP
              </button>

              {/* <button
              type="button"
              className="btn btn-link primary_color text-decoration-none text-dark-grey"
              onClick={handleResendOTP}
              disabled={otpTimer > 0}
            >
              Resend OTP
            </button> */}

              {/* <div className="otp-timer">{formatTime(otpTimer)}</div> */}
            </>
          ):(<form onSubmit={handleLogin}>
            <input
              type="text"
              value={email}
              id='userlogin'
              placeholder="Email ID / Mobile Number"
              className="form-control"
              onChange={handleEmailChange}
              required
            />
 
            <p className="text-start">
                 {isNaN(email) && email != "" ? <div className="text-danger">{emailError}</div>:null}
                 {!isNaN(email) && email != "" ? <div className="text-danger">{phoneError}</div>:null}
            </p>
 
            {/* { <div className="g-recaptcha text-center">
             <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcgFOwoAAAAABtI1a627shZlgWc2c8m5kuRVTKF"// {process.env.DATA_SITEKEY}
                badge="bottomright"
                onChange={onChange}
              />
            </div>} */}
 
            <div className="d-flex justify-content-center flex-column align-items-center mt-4">
              <button
                type="button"
                className="btn btn-md btn-primary w-100 mb-2"
                onClick={handleLogin}
              >
                Sign In
              </button>
            </div>
          </form>)}
      </div>
      </Modal>
  )
}

export default LoginModal;